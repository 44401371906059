import axios from "axios"

class BackendModel {
    getBaseUrl() {
        console.log(window.location)
        if (window.location.hostname == 'simpoapp.io') {
            return 'https://' + window.location.hostname
        }

        return 'http://' + window.location.hostname + ":3000"
    }

    getBackofficeSessionId() {
        let name = 'backoffice_session_id'
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        let backoffice_session_id = parts.pop().split(';').shift()
        return backoffice_session_id
    }

    async backendRequest(url, params) {
        try {
            params.backoffice_session_id = this.getBackofficeSessionId()
            // If it's formdata for file uploading
            if (typeof params.append === 'function') {
                params.append('backoffice_session_id', this.getBackofficeSessionId())
            }
            let res = await axios.post(this.getBaseUrl() + url, params)
            return res
        } catch (e) {
            console.log(e)
        }
    }
    
    getPointSessionId() {
        let name = 'point_session_id'
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        let point_session_id = parts.pop().split(';').shift()
        return point_session_id
    }

    async point_Request(url, params) {
        try {
            params.point_session_id = this.getPointSessionId()
            // If it's formdata for file uploading
            if (typeof params.append === 'function') {
                params.append('point_session_id', this.getPointSessionId())
            }
            let res = await axios.post(this.getBaseUrl() + url, params)
            return res
        } catch (e) {
            console.log(e)
        }
    }
}


export default BackendModel